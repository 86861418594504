var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-btn",
    {
      class: { active: _vm.active },
      attrs: { variant: _vm.variant, "aria-label": "BtnFavorite" },
      nativeOn: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.toggleFavorite()
        },
      },
    },
    [
      _c("base-icon", { class: _vm.iconClass, attrs: { name: "favorites" } }),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _vm.modals.collectionActions
        ? _c("modal-collection-actions", {
            attrs: {
              product: {
                id: _vm.id,
                inFavorites: _vm.active,
              },
            },
            model: {
              value: _vm.modals.collectionActions,
              callback: function ($$v) {
                _vm.$set(_vm.modals, "collectionActions", $$v)
              },
              expression: "modals.collectionActions",
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }