<template>
    <base-btn
        :variant="variant"
        :class="{ 'active' : active }"
        aria-label="BtnFavorite"
        @click.native.stop="toggleFavorite()"
    >
        <base-icon
            name="favorites"
            :class="iconClass"
        />
        <slot />

        <modal-collection-actions
            v-if="modals.collectionActions"
            v-model="modals.collectionActions"
            :product="{
                id,
                inFavorites: active,
            }"
        />
    </base-btn>
</template>

<script>
import { defineAsyncComponent } from 'vue';

import { MODAL_USER_TYPES } from '../utils/constants';
import { modals } from '../utils/mixins';

const ModalCollectionActions = defineAsyncComponent(() => import('./ModalCollectionActions.vue'));

export default {
    components: {
        ModalCollectionActions,
    },

    mixins: [
        modals,
    ],

    props: {
        id: {
            default: null,
            type: Number,
        },
        variant: {
            default: 'favorites',
            type: String,
        },
        active: {
            default: false,
            type: Boolean,
        },
        iconClass: {
            type: String,
            default: null,
        },
    },

    methods: {
        async toggleFavorite() {
            if (!this.$store.getters['user/isLoggedin']) {
                this.openModalUser(MODAL_USER_TYPES.LOGIN);
                return;
            }

            this.openModal('collectionActions');
        },
        openModalUser(type) {
            this.$emitter.emit('modal', {
                modal: 'user',
                type,
            });
        },
    },
};
</script>
